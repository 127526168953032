import React from 'react'
import {Link} from 'gatsby'
const policy = () => {
  return (
    <div>
      <h1>Policy</h1>
      <Link to="">Home</Link>
    </div>
  )
}

export default policy
